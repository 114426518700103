import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useDebounce } from '../../../hooks';
import { CUSTOMER_TABVIEW_COLUMNS, NEWS_FEED, ROUTES, ROLES } from '../../../constants';
import { getAccountByID, popup, deleteAccount, handleScroll } from '../../../utils';
import { ButtonComponent, DeleteConfirmationModal, EditButton } from '../../shared';
import {
    BlueArrow,
    Delete,
    DocumentRequest,
    EmptyDoc,
    IconHover1,
    ScoreCard,
} from '../../../assets/svg';
import { setLoaderVisibility, setSidePanel } from '../../../redux';
import { SelectPlatformModal } from './SelectPlatformModal';
import { formatPhoneToUSFormat } from '../../../utils/common/formatHelpers';
import { useSelector } from 'react-redux';
import useWindowSize from '../../../hooks/useWindowSize';

export const CustomerDetailsTab = ({
    setTabOpen,
    tabData,
    setSelectedRowIndex,
    debouncedApiCallFromParent,
}) => {
    const scrollBar = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const role = useSelector((s) => s.user.role);
    const showAction = useSelector((s) => s.user.isUserAdmin);
    const csmRole = role === ROLES.CSM;
    const isContentManager = useSelector((state) => state.user.role === ROLES.CONTENT_MANAGER);
    const [accountUserInfo, setAccountUserInfo] = useState({
        id: '',
        name: '',
        primary_user_id: '',
        primary_user_full_name: '',
        phone: '',
        email: '',
        csm_id: '',
        csm_user_name: '',
        hiring_clients: [],
        tpas: [],
    });
    const [openSelectPlatformModal, setOpenSelectPlatformModal] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isScrolling, setIsScrolling] = useState(false);
    const { width } = useWindowSize();
    const isSmallScreen = width < 560;

    const handleScrolling = () => {
        handleScroll(setIsScrolling, scrollBar, 2000);
    };

    const debouncedApiCall = useDebounce(() => {
        getAccountByID({ id: tabData })
            .then((res) => {
                if (res?.data) {
                    setAccountUserInfo((prevState) => ({
                        ...prevState,
                        id: res.data?.account?.account_id,
                        name: res.data?.account?.name,
                        email: res.data?.account?.email,
                        phone: formatPhoneToUSFormat(res.data?.account?.phone),
                        csm_id: res?.data?.account?.csm_id,
                        csm_user_name: res?.data?.account?.csm_name,
                        primary_user_full_name: res?.data?.account?.users?.find(
                            (user) => user.is_primary === 1,
                        )?.full_name,
                        hiring_clients: res?.data?.hiringClients,
                        tpas: res?.data?.platforms,
                        scorecards: res?.data?.scorecards,
                    }));
                }
                dispatch(setLoaderVisibility(false));
            })
            .catch((error) => {
                dispatch(setLoaderVisibility(false));
                popup('error', error.message);
            });
    }, 500);

    const handleCreateScorecardClick = () => {
        setOpenSelectPlatformModal(true);
    };

    useEffect(() => {
        dispatch(setLoaderVisibility(true));
        debouncedApiCall();
    }, [tabData]);

    const documentRequest = [];

    const handleDeleteClick = ({ selectedItemId, setIsDeleteModal }) => {
        dispatch(setLoaderVisibility(true));
        deleteAccount({ id: selectedItemId })
            .then((res) => {
                debouncedApiCallFromParent();
                setTabOpen(false);
                setIsDeleteModal(false);
                popup('error', res.message);
            })
            .catch((error) => {
                dispatch(setLoaderVisibility(false));
                popup('error', error.message);
            });
    };

    return (
        <>
            <div className={'tab-open-area show' + `${isContentManager ? ' w-25 ' : ''}`}>
                {openSelectPlatformModal && (
                    <SelectPlatformModal
                        openSelectPlatformModal={openSelectPlatformModal}
                        setOpenSelectPlatformModal={setOpenSelectPlatformModal}
                        customer={accountUserInfo}
                    />
                )}
                <div className='position-relative ps-3'>
                    {!openSelectPlatformModal && (
                        <ButtonComponent
                            className='close-btn bg-white'
                            clickHandler={() => {
                                setTabOpen(false);
                                dispatch(setSidePanel({ sidePanel: false, id: '' }));
                                setSelectedRowIndex(0);
                            }}
                        >
                            <BlueArrow width={16} height={16} className={''} />
                        </ButtonComponent>
                    )}
                    <div
                        onScroll={handleScrolling}
                        className={`position-relative content-area px-4 py-3 ${isScrolling ? 'scrolling' : 'not-scrolling'}`}
                    >
                        <div className='row'>
                            <div className='d-flex position-realtive align-items-center edit-button-customer-tab'>
                                <h6 className='mb-0 me-2'>Edit Client</h6>
                                <div>
                                    {showAction && (
                                        <EditButton
                                            onClick={(e) => {
                                                e.stopPropagation();
                                            }}
                                            navigateTo={ROUTES.ADMIN.CUSTOMER_EDIT}
                                            id={accountUserInfo.id}
                                        />
                                    )}
                                </div>
                            </div>
                            <div className='col-4 right-side'>
                                <div className='d-flex align-items-start flex-column detail-area'>
                                    {CUSTOMER_TABVIEW_COLUMNS.map((column, index) => {
                                        return (
                                            <div key={`tabview${index}`}>
                                                <p
                                                    className={`title ${column.name === 'Hiring Client' && csmRole && 'margin-100'}`}
                                                >
                                                    {column.name}{' '}
                                                </p>
                                                <div className='text d-flex flex-column  align-items-left'>
                                                    {Array.isArray(
                                                        accountUserInfo[column.dataField],
                                                    )
                                                        ? accountUserInfo[column.dataField].length
                                                            ? accountUserInfo[column.dataField].map(
                                                                  (data) =>
                                                                      column.customFormatter(data),
                                                              )
                                                            : `No ${column.name} found`
                                                        : column.dataField === 'users'
                                                          ? column.customFormatter({
                                                                data: accountUserInfo,
                                                            })
                                                          : accountUserInfo[column.dataField]}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            {!isContentManager && (
                                <div className='col-8 left-side'>
                                    <div className='customer-area-btn d-flex flex-column flex-md-row align-items-end justify-content-end'>
                                        {isSmallScreen ? (
                                            <span
                                                id='action-btn'
                                                title='Create Scorecard'
                                                onClick={() => {
                                                    if (accountUserInfo.tpas.length) {
                                                        handleCreateScorecardClick();
                                                    } else {
                                                        popup('error', 'Platform not Available');
                                                    }
                                                }}
                                            >
                                                <ScoreCard />
                                            </span>
                                        ) : (
                                            <ButtonComponent
                                                className='w-auto ssc-secondary-white-btn'
                                                text={'Create Scorecard'}
                                                clickHandler={() => {
                                                    if (accountUserInfo.tpas.length) {
                                                        handleCreateScorecardClick();
                                                    } else {
                                                        popup('error', 'Platform not Available');
                                                    }
                                                }}
                                            ></ButtonComponent>
                                        )}
                                        {isSmallScreen ? (
                                            <span
                                                id='action-btn'
                                                title='Request Documents'
                                                onClick={() => {
                                                    return navigate(ROUTES.ADMIN.DOCUMENT_REQUEST, {
                                                        state: {
                                                            accountUserDetails: accountUserInfo,
                                                        },
                                                    });
                                                }}
                                            >
                                                <DocumentRequest />
                                            </span>
                                        ) : (
                                            <ButtonComponent
                                                className='w-auto ssc-primary-green-btn'
                                                text={'Request Documents'}
                                                clickHandler={() => {
                                                    return navigate(ROUTES.ADMIN.DOCUMENT_REQUEST, {
                                                        state: {
                                                            accountUserDetails: accountUserInfo,
                                                        },
                                                    });
                                                }}
                                            ></ButtonComponent>
                                        )}
                                        <span
                                            id='action-btn'
                                            title='Delete'
                                            onClick={() => setIsDeleteModalOpen((pre) => !pre)}
                                        >
                                            <Delete width={16} height={16} />
                                        </span>
                                    </div>
                                    <p className='text-end sub-title'>
                                        {/* Recently Uploaded Documents */}
                                    </p>
                                    {/* for empty  document request*/}
                                    {!documentRequest.length ? (
                                        <div className='empty-doc d-flex align-items-end justify-content-end flex-column'>
                                            <div className=''>
                                                {width > 660 ? (
                                                    <EmptyDoc width={176} height={136} />
                                                ) : null}
                                            </div>
                                            <div className='text-center'>
                                                <span className='text-center text-line'>
                                                    {/*There are no document upload requests */}
                                                </span>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className='filled-doc d-flex align-items-end justify-content-end'>
                                            {documentRequest.map((doc) => {
                                                return (
                                                    <div
                                                        key={doc.date}
                                                        className='d-flex flex-column align-items-start justify-content-start'
                                                    >
                                                        <span>
                                                            {doc.date} <br />
                                                            <span>{doc.time}</span>
                                                        </span>
                                                        <div className='doc-hov position-relative'>
                                                            <div className=' position-relative'>
                                                                <img
                                                                    src={doc.image}
                                                                    alt='doc'
                                                                    width='67'
                                                                    height='61'
                                                                    className='doc-img '
                                                                />
                                                            </div>
                                                            <IconHover1
                                                                className={'icon1'}
                                                                height={16}
                                                                width={16}
                                                            />{' '}
                                                            <IconHover1
                                                                className={'icon1'}
                                                                height={16}
                                                                width={16}
                                                            />
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}

                                    <div className='news-feed-btn d-flex align-items-center justify-content-end'>
                                        <ButtonComponent
                                            className='w-auto ssc-secondary-white-btn d-flex align-items-center'
                                            text={NEWS_FEED.TITLE}
                                            clickHandler={() => {
                                                return navigate(ROUTES.ADMIN.NEWS_FEED_ADMIN, {
                                                    state: {
                                                        accountUserDetails: accountUserInfo,
                                                    },
                                                });
                                            }}
                                        ></ButtonComponent>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {isDeleteModalOpen && (
                <DeleteConfirmationModal
                    isDeleteModal={isDeleteModalOpen}
                    setIsDeleteModal={setIsDeleteModalOpen}
                    selectedItemId={tabData}
                    handleDeleteClick={handleDeleteClick}
                    deleteButtonName={'Client'}
                />
            )}
        </>
    );
};
CustomerDetailsTab.propTypes = {
    tabData: PropTypes.string,
    setTabOpen: PropTypes.func,
    setSelectedRowIndex: PropTypes.func,
    debouncedApiCallFromParent: PropTypes.func,
};
