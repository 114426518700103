import React from 'react';

import { ManagementList } from '../ManagementList/ManagementList';
import { CSM, CSM_COLUMNS, ROLES } from '../../../constants';

export const CsmListing = () => {
    return (
        <ManagementList
            enableActiveUserButton
            forRoles={[ROLES.CSM]}
            activateUserButtonTitle='Activate CSMs'
            buttonText={CSM.BUTTON_TEXT}
            stickyColumns={['Name']}
            pageName={CSM.PAGE_NAME}
            searchBarPlaceholder={CSM.SEARCH_BAR_PLACEHOLDER}
            heading={CSM.PAGE_HEADING}
            columns={CSM_COLUMNS}
        />
    );
};
