import React, { useEffect, useState } from 'react';
import { useDebounce, usePagination } from '../../../hooks';
import { getAllUsers, popup, updateCSMStatus } from '../../../utils';
import { Modal } from 'react-bootstrap';
import { setLoaderVisibility, setSelectedHiringClients } from '../../../redux';
import { useDispatch } from 'react-redux';
import { DEACTIVATED_CSM_COLUMNS } from '../../../constants';
import { isEmpty } from 'lodash';
import { Table } from '..';
import InputWithSearch from '../common/InputWithSearch';
import { useSelector } from 'react-redux';

const ActivateUserModal = ({
    showModal,
    setShowModal,
    response,
    setResponse,
    forRoles = [],
    searchBarPlaceholder = 'Search...',
    pageName = '',
}) => {
    const dispatch = useDispatch();
    const [deactivatedCSM, setDeactivatedCSM] = useState({ data: [], count: 0 });
    const [search, setSearch] = useState('');
    const [isLoading, setIsLoading] = useState('');
    const pagination = usePagination(deactivatedCSM?.data.length, deactivatedCSM?.count);
    const { selectedHiringClients } = useSelector((s) => s.accountArray);

    const getDeactivatedUsers = useDebounce(async () => {
        setIsLoading(true);
        try {
            const response = await getAllUsers({
                offset: pagination.offset,
                limit: pagination.limit,
                search: search,
                filter: { status: false },
                role: forRoles,
            });
            setDeactivatedCSM({ data: response.data, count: response.count });
        } catch (error) {
            popup('error', error.message);
        } finally {
            dispatch(setLoaderVisibility(false));
            setIsLoading(false);
        }
    }, 1000);

    const activateUser = useDebounce(async () => {
        try {
            dispatch(setLoaderVisibility(true));
            const result = await updateCSMStatus({
                status: 1,
                ids: selectedHiringClients,
            });

            if (result?.statusCode === 200) {
                const csmToAppend = deactivatedCSM?.data.filter((csm) =>
                    selectedHiringClients.includes(csm.id),
                );
                const data = [...csmToAppend, ...response.data];
                data.length = 10;
                setResponse({
                    data,
                    count: response?.count + selectedHiringClients.length,
                });
                popup('success', result.message);
                dispatch(setSelectedHiringClients([]));
                setShowModal(false);
            }
        } catch (error) {
            popup('error', error.message);
        } finally {
            dispatch(setLoaderVisibility(false));
        }
    }, 1000);

    useEffect(() => {
        if (showModal) {
            !search && dispatch(setLoaderVisibility(true));
            getDeactivatedUsers();
        }
        return () => {
            setDeactivatedCSM({ data: [], count: 0 });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showModal, search, pagination.limit, pagination.offset]);

    return (
        <Modal
            show={showModal}
            onHide={() => {
                setShowModal(false);
                dispatch(setSelectedHiringClients([]));
            }}
            size='lg'
            dialogClassName='overflow-hidden'
            fullscreen='md-down'
        >
            <Modal.Header closeButton>
                <Modal.Title className='me-3' style={{ fontSize: '18px' }}>
                    {pageName}
                </Modal.Title>
                <InputWithSearch
                    search={search}
                    setSearch={setSearch}
                    placeholder={searchBarPlaceholder}
                    className={'w-50'}
                    isLoading={isLoading}
                />
            </Modal.Header>
            <Modal.Body className='position-relative' style={{ minHeight: '42.75rem' }}>
                {!isEmpty(deactivatedCSM) && (
                    <Table
                        columns={DEACTIVATED_CSM_COLUMNS}
                        data={deactivatedCSM?.data}
                        pagination={pagination}
                        tableStyle={{
                            height: '36.25rem',
                            overflow: 'auto',
                        }}
                        stickyColumns={['Name']}
                        pageName={pageName}
                    />
                )}
            </Modal.Body>
            <Modal.Footer>
                <div className='d-flex w-100 align-items-center justify-content-between modal-btn'>
                    <span
                        style={{ color: '#738da6', fontSize: '14px' }}
                    >{`${selectedHiringClients.length}/${deactivatedCSM?.count} Selected`}</span>
                    <button
                        type='submit'
                        onClick={activateUser}
                        className='ssc-primary-green-btn py-2 w-auto disabled-hover rounded-2'
                        disabled={selectedHiringClients.length === 0}
                    >
                        Activate
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default ActivateUserModal;
