import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Edit, IconPlus, Swap } from '../../../assets/svg';
import { DeleteButton } from '../../shared';
import { getAllPlatforms, popup, updateHiringClientName } from '../../../utils';
import { HiringClientSection } from './HiringClientSection';
import { EDIT_CUSTOMER as EDIT, ROLES, ROUTES, SOURCE, SYMBOL } from '../../../constants';
import { useSelector } from 'react-redux';
import { EditFileNameModal } from '../../shared/Modal/EditFileNameModal';
import { useDispatch } from 'react-redux';
import { setLoaderVisibility } from '../../../redux';
import useWindowSize from '../../../hooks/useWindowSize';
import SSCSelect from '../../shared/SSCSelect';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';

export const ManagePlatformBox = ({ accountData, debouncedApiCall }) => {
    const [realData, setRealData] = useState([]);
    const role = useSelector((s) => s.user.role);
    const [data, setData] = useState([]);
    const [editPlatform, setEditPlatform] = useState(false);
    const [tpa, setTpa] = useState('');
    const [platformBox, setPlatformBox] = useState(true);
    const [searchPlatform, setSearchPlatform] = useState('');
    const [dropdownData, setDropDownData] = useState([]);
    const [addedPlatform, setAddedPlatform] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [hcDetails, setHcDetails] = useState({ name: '', id: '' });
    const dispatch = useDispatch();
    const { width } = useWindowSize();
    const navigate = useNavigate();

    const handlePlatformDelete = () => {
        if (tpa) {
            setData((pre) => pre.filter((pf) => pf.id !== tpa.id));
            setTpa(data[0]);
        } else {
            popup('error', EDIT.POPUP_MSG.PLEASE_SELECT_PLATFORM);
        }
    };

    const getPlatformData = () => {
        getAllPlatforms(1, 10, searchPlatform)
            .then((res) => setDropDownData(res.data))
            .catch((err) => popup('error', err.msg));
    };

    const handleAddPlatForm = () => {
        setEditPlatform(true);
        setPlatformBox(false);
        getPlatformData();
    };

    const handleEdit = () => {
        setEditPlatform(true);
        setPlatformBox(true);
    };

    const handleClose = () => {
        setEditPlatform(false);
        debouncedApiCall();
        setData((pre) => {
            return { ...pre, platformsWithHC: [...accountData.platformsWithHC] };
        });
    };
    const saveAddedPlatform = () => {
        if (addedPlatform) {
            const isPlatformExist = data.find((platform) => platform.id === addedPlatform.id);

            isPlatformExist
                ? popup('error', EDIT.POPUP_MSG.PLATFORM_EXIST)
                : (setData((pre) => {
                      return [...pre, addedPlatform];
                  }),
                  setTpa(addedPlatform),
                  setPlatformBox(true),
                  setAddedPlatform(''));
        } else {
            popup('error', EDIT.POPUP_MSG.PLEASE_SELECT_PLATFORM);
        }
    };

    useEffect(() => {
        getPlatformData();
    }, [searchPlatform]);

    useEffect(() => {
        if (accountData && accountData?.platformsWithHC) {
            setData(accountData?.platformsWithHC);
            setRealData(accountData?.platformsWithHC);
            if (tpa) {
                setTpa(accountData?.platformsWithHC?.find((ele) => ele?.id === tpa?.id));
            } else {
                setTpa(accountData?.platformsWithHC?.[0]);
            }
        }
        return () => {
            setData([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountData]);

    const renameHiringClient = async ({ fileName }) => {
        try {
            dispatch(setLoaderVisibility(true));
            const data = await updateHiringClientName({
                data: { name: fileName },
                id: hcDetails.id,
            });
            popup('success', data.message);
            setShowEditModal(false);
            setHcDetails({ name: '', id: '' });
            debouncedApiCall();
        } catch (error) {
            popup('error', error.message);
        } finally {
            dispatch(setLoaderVisibility(false));
        }
    };

    return (
        [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.CSM].includes(role) && (
            <div className='tabs-set h-25'>
                <div className='d-flex justify-content-end '>
                    {!editPlatform && (
                        <div className='d-flex gap-2'>
                            <button
                                title='Edit'
                                id='action-btn'
                                onClick={handleEdit}
                                className='bg-transparent'
                            >
                                <Edit className='cursor-pointer' />
                            </button>
                            <button
                                title='Reorder Hiring Clients'
                                id='action-btn'
                                onClick={() => {
                                    navigate(ROUTES.ADMIN.CUSTOMER_HIRING_CLIENT_ORDER, {
                                        state: { accountData: accountData, tpa: tpa },
                                    });
                                }}
                                className='bg-transparent'
                            >
                                <Swap />
                            </button>
                        </div>
                    )}
                </div>
                <div className=''>
                    {/* Platform Section */}
                    <div className='d-flex gap-2 flex-column justify-content-between align-item-center ps-lg-2 pe-lg-2 '>
                        <div className='d-flex justify-content-start  align-item-center gap-4 overflow-y-auto '>
                            {data.length ? (
                                <div className='d-flex w-100 justify-content-between align-item-center'>
                                    <div className='d-flex align-item-center gap-2 justify-content-start'>
                                        {data.map((platform, index) => (
                                            <span
                                                key={`${platform.id}-${index}`}
                                                className={`cursor-pointer ${platform.id === tpa.id ? 'ssc-primary-green-btn' : 'ssc-secondary-white-btn'} 
                                button-green-filled`}
                                                onClick={() => setTpa(platform)}
                                            >
                                                {platform.name ? platform.name : ''}
                                                <sup>{SYMBOL.REGISTERED}</sup>
                                            </span>
                                        ))}
                                    </div>
                                    {tpa && editPlatform && (
                                        <DeleteButton
                                            height={20}
                                            width={20}
                                            handleClick={handlePlatformDelete}
                                            className='ms-4'
                                        />
                                    )}
                                </div>
                            ) : (
                                !editPlatform && (
                                    <span className='d-flex align-items-center ms-1 '>
                                        {EDIT.BUTTON_TEXT.NO_PLATFORM}
                                    </span>
                                )
                            )}
                        </div>
                        {editPlatform && (
                            <div
                                className={`${!platformBox ? 'w-100' : ''} d-flex justify-content-start align-item-center gap-3 `}
                            >
                                {platformBox ? (
                                    <div className='d-flex align-items-center w-100 justify-content-between   '>
                                        <button
                                            onClick={handleAddPlatForm}
                                            className='ssc-primary-green-btn button-green-filled'
                                            style={{ margin: '4px 0' }}
                                        >
                                            {EDIT.BUTTON_TEXT.ADD_NEW_PLATFORM}
                                        </button>
                                    </div>
                                ) : (
                                    <div className='d-flex gap-2 gap-sm-4 flex-wrap align-items-center w-100 pt-lg-3 '>
                                        <SSCSelect
                                            placeholder={'Select Platforms'}
                                            options={dropdownData?.map((ele) => ({
                                                value: ele,
                                                label: ele.name,
                                            }))}
                                            value={
                                                !isEmpty(addedPlatform)
                                                    ? {
                                                          value: addedPlatform,
                                                          label: addedPlatform.name,
                                                      }
                                                    : null
                                            }
                                            onInputChange={setSearchPlatform}
                                            onChange={(d) => setAddedPlatform(d?.value)}
                                            formatOptionLabel={({ label }) => {
                                                return (
                                                    <div className='text-txt'>
                                                        <span>{label}</span>
                                                        <sup>{SYMBOL.REGISTERED}</sup>
                                                    </div>
                                                );
                                            }}
                                            isClearable
                                            className={'w-50'}
                                        />
                                        <div className='d-flex align-items-center gap-2 gap-sm-4'>
                                            {width > 768 ? (
                                                <button
                                                    onClick={saveAddedPlatform}
                                                    className='ssc-primary-green-btn rounded-1'
                                                    style={{
                                                        minWidth: 'max-content',
                                                        fontSize: '16px',
                                                    }}
                                                    title='Add Platform'
                                                >
                                                    Add Platform
                                                </button>
                                            ) : (
                                                <span
                                                    id='action-btn-green'
                                                    onClick={saveAddedPlatform}
                                                >
                                                    <IconPlus />
                                                </span>
                                            )}
                                            <span
                                                onClick={() => {
                                                    setPlatformBox(true);
                                                }}
                                                className='btn-close ms-lg-5 p-1'
                                                title='Close'
                                            ></span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    {/* Hiring Client Section if edit */}
                    {editPlatform && tpa && (
                        <HiringClientSection
                            handleClose={handleClose}
                            id={accountData.account.account_id}
                            tpa={tpa}
                            realData={realData}
                            hiringClientData={data}
                            setHiringClientData={setData}
                            debouncedApiCall={debouncedApiCall}
                        />
                    )}
                    {/* Hiring Client Section if no edit */}
                    {tpa && !editPlatform && (
                        <div
                            className='border user-create-form mt-2'
                            style={{ margin: 0 }}
                            key={tpa?.id}
                        >
                            <div className={`scroll-manage-hiring-clients`} id='wrapper'>
                                {tpa?.hiringClients?.length ? (
                                    <table className='table'>
                                        <thead style={{ zIndex: 99 }}>
                                            <tr>
                                                <th className='fw-normal lh-1 text-secondary pb-2 px-2 '>
                                                    {width > 720 ? 'Order' : '#'}
                                                </th>
                                                <th className='fw-normal lh-1 text-secondary pb-2 px-2 text-nowrap sticky-th-left'>
                                                    Hiring Client Name
                                                </th>
                                                <th className='fw-normal lh-1 text-secondary pb-2 px-2'>
                                                    Grade
                                                </th>
                                                <th className='fw-normal lh-1 text-secondary pb-2 px-2 text-nowrap'>
                                                    Created By
                                                </th>
                                                <th className='fw-normal lh-1 text-secondary pb-2 px-2'></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tpa.hiringClients?.map((hc, index) => {
                                                return (
                                                    <tr key={hc.id}>
                                                        <td className='edit-box-row'>
                                                            {index + 1}.
                                                        </td>
                                                        <td className='sticky-td-left'>
                                                            {hc.name}
                                                        </td>
                                                        <td>{hc.hiring_client_grade || '-'}</td>
                                                        <td>
                                                            {hc.is_from_salesforce ? (
                                                                <span className='text-danger'>
                                                                    {SOURCE.SCHEDULAR}
                                                                </span>
                                                            ) : (
                                                                SOURCE.PORTAL
                                                            )}
                                                        </td>
                                                        <td>
                                                            {!hc.is_from_salesforce && (
                                                                <span
                                                                    className='p-2'
                                                                    style={{ cursor: 'pointer' }}
                                                                    title='Rename Hiring Client'
                                                                    onClick={() => {
                                                                        setHcDetails({
                                                                            name: hc.name,
                                                                            id: hc.id,
                                                                        });
                                                                        setShowEditModal(true);
                                                                    }}
                                                                >
                                                                    <Edit />
                                                                </span>
                                                            )}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                ) : (
                                    <span className='text-special text-center ms-5'>
                                        {EDIT.BUTTON_TEXT.NO_HC}
                                    </span>
                                )}
                            </div>
                            <EditFileNameModal
                                key={hcDetails.id}
                                showEditModal={showEditModal}
                                setShowEditModal={setShowEditModal}
                                modalTitle='Rename Hiring Client'
                                inputLabel='Hiring Client Name'
                                inputPlaceholder='Enter Hiring Client Name'
                                currentFileName={hcDetails.name}
                                onClose={() => setHcDetails({ name: '', id: '' })}
                                onSubmit={renameHiringClient}
                            />
                        </div>
                    )}
                </div>
            </div>
        )
    );
};

ManagePlatformBox.propTypes = {
    accountData: PropTypes.object,
    setReload: PropTypes.func,
};
