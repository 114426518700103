import React from 'react';

import { ManagementList } from '../ManagementList/ManagementList';
import { SALES_AGENT, CSM_COLUMNS, ROLE_NAME, ROLES } from '../../../constants';

export const SalesAgentListing = () => {
    return (
        <ManagementList
            enableActiveUserButton
            forRoles={[ROLES.SALES_AGENT]}
            activateUserButtonTitle={`Activate ${ROLE_NAME.SALES_AGENT}s`}
            buttonText={SALES_AGENT.BUTTON_TEXT}
            stickyColumns={['Name']}
            pageName={SALES_AGENT.PAGE_NAME}
            searchBarPlaceholder={SALES_AGENT.SEARCH_BAR_PLACEHOLDER}
            heading={SALES_AGENT.PAGE_HEADING}
            columns={CSM_COLUMNS}
        />
    );
};
