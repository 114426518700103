import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ButtonComponent } from '../Buttons/ButtonComponent';
import { CardRightArrow } from '../../../assets/svg';
import { useSelector } from 'react-redux';
import { sidebarOptions } from './constant';
import useWindowSize from '../../../hooks/useWindowSize';
import { getLocalStorageItem } from '../../../utils';
import { LOCAL_STORAGE } from '../../../constants';
import PoweredByLogo from '../../../assets/img/powered-by-SSC-only.webp';
import SidebarSubOptionItems from '../Popovers/SidebarSubOptionItems';

export function SideBar({ sidebarFor, currentDashboardRoute, setCurrentDashboardRoute }) {
    const [show_sidebar, toggleSidebar] = useState(false);
    const [accordionOpen, setAccordionOpen] = useState([]);
    const role = useSelector((s) => s?.user?.role);
    const isUserPrimary = useSelector((s) => s.accountArray.isUserPrimary);
    const { width, height } = useWindowSize();
    const isCSMCaseload = getLocalStorageItem(LOCAL_STORAGE.IS_CSM_CASELOAD);

    const clickHandler = () => {
        toggleSidebar(!show_sidebar);
    };

    return (
        <>
            <ButtonComponent
                className={`ssc-slider-btn position-absolute d-flex align-items-center justify-content-center d-lg-none d-block bg-white p-1 ${show_sidebar ? 'open' : ''}`}
                text={''}
                type={'button'}
                id={'toggleButton'}
                clickHandler={clickHandler}
                Digital
                Document
                Library
            >
                {show_sidebar ? (
                    <CardRightArrow
                        size={14}
                        style={{ transform: 'rotate(180deg)' }}
                        stroke='var(--col-dark)'
                    />
                ) : (
                    <CardRightArrow size={14} stroke='var(--col-dark)' />
                )}
            </ButtonComponent>
            <div
                className={`col-lg-2 ssc-sliderbar ${isCSMCaseload && 'isImpersonated'} ${show_sidebar ? 'show' : ''}`}
                id='MenuSidebarMobileView2'
            >
                <ul className='link-area mb-0 ps-0'>
                    {sidebarOptions(sidebarFor).map((option, index) => {
                        return sidebarFor === 'admin' ? (
                            option?.allowedRoles.includes(role) ? (
                                option?.subOptions?.length > 0 ? (
                                    <SidebarSubOptionItems
                                        index={index}
                                        option={option}
                                        setAccordionOpen={setAccordionOpen}
                                        accordionOpen={accordionOpen}
                                        setCurrentDashboardRoute={setCurrentDashboardRoute}
                                        currentDashboardRoute={currentDashboardRoute}
                                        sidebarFor={sidebarFor}
                                        isUserPrimary={isUserPrimary}
                                        role={role}
                                        width={width}
                                        show_sidebar={show_sidebar}
                                    />
                                ) : (
                                    <li className='links-set' key={index}>
                                        <Link
                                            onClick={() => {
                                                setCurrentDashboardRoute(option.route[0]);
                                            }}
                                            to={option.route[0]}
                                            className={`d-flex align-items-center justify-content-start link-line ${option.route.includes(currentDashboardRoute) && 'active'}`}
                                            title={option.name}
                                        >
                                            <option.icon color='#738DA6' height={16} width={16} />
                                            <span>{option.name}</span>
                                        </Link>
                                    </li>
                                )
                            ) : null
                        ) : option?.subOptions?.length > 0 ? (
                            <SidebarSubOptionItems
                                index={index}
                                option={option}
                                setAccordionOpen={setAccordionOpen}
                                accordionOpen={accordionOpen}
                                setCurrentDashboardRoute={setCurrentDashboardRoute}
                                currentDashboardRoute={currentDashboardRoute}
                                sidebarFor={sidebarFor}
                                isUserPrimary={isUserPrimary}
                                role={role}
                                width={width}
                                show_sidebar={show_sidebar}
                            />
                        ) : (
                            <li className='links-set' key={index}>
                                <Link
                                    onClick={() => {
                                        setCurrentDashboardRoute(option.route[0]);
                                    }}
                                    to={option.route[0]}
                                    className={`d-flex align-items-center justify-content-start link-line ${option.route.includes(currentDashboardRoute) && 'active'}`}
                                    title={option.name}
                                >
                                    <option.icon color='#738DA6' height={16} width={16} />
                                    <span>{option.name}</span>
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            </div>
            {width > 991 && height > 786 && (
                <div className='d-flex position-absolute bottom-0 mb-2 ms-2'>
                    <img src={PoweredByLogo} alt='powered by ssc' width={200} />
                </div>
            )}
        </>
    );
}

SideBar.propTypes = {
    sidebarFor: PropTypes.string.isRequired,
    currentDashboardRoute: PropTypes.string.isRequired,
    setCurrentDashboardRoute: PropTypes.func.isRequired,
};
