import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { AdminPageHeader } from '../Common/AdminPagesHeader';
import { setLoaderVisibility } from '../../../redux';
import { usePagination, useDebounce } from '../../../hooks';
import { popup, getAllUsers } from '../../../utils';

import { OptionIcon } from '../../../assets/svg/OptionIcon';
import ActivateUserModal from '../../shared/Modal/ActivateCSMModal';
import { Table } from '../../shared';
import { useSelector } from 'react-redux';
import { ROLES } from '../../../constants';

export const ManagementList = ({
    forRoles,
    status,
    heading,
    buttonText,
    searchBarPlaceholder,
    columns,
    stickyColumns,
    pageName,
    enableActiveUserButton,
    activateUserButtonTitle,
}) => {
    const dispatch = useDispatch();
    const [response, setResponse] = useState({ data: [], count: 0 });
    const [search, setSearch] = useState('');
    const [showModal, setShowModal] = useState(false);
    const pagination = usePagination(response.data.length, response.count);
    const role = useSelector((s) => s.user.role);

    const debouncedApiCall = useDebounce(() => {
        getAllUsers({
            limit: pagination.limit,
            offset: pagination.offset,
            search: search,
            filter: { status },
            role: forRoles,
        })
            .then((res) => {
                if (res?.data) setResponse({ data: res.data, count: res.count });
                dispatch(setLoaderVisibility(false));
            })
            .catch((error) => {
                dispatch(setLoaderVisibility(false));
                popup('error', error.message);
            });
    }, 500);

    useEffect(() => {
        !search && dispatch(setLoaderVisibility(true));
        debouncedApiCall();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination.limit, pagination.offset, search]);

    return (
        <>
            <AdminPageHeader
                heading={heading}
                buttonText={buttonText}
                searchBarPlaceholder={searchBarPlaceholder}
                setSearch={setSearch}
                count={pagination.dataCount}
                setCurrentPage={pagination.setCurrentPage}
                backbutton
            />
            <Table
                columns={columns}
                data={response.data}
                pagination={pagination}
                pageName={pageName}
                stickyColumns={stickyColumns}
                debouncedApiCall={debouncedApiCall}
            />
            {showModal && [ROLES.SUPER_ADMIN, ROLES.ADMIN].includes(role) && (
                <ActivateUserModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    response={response}
                    setResponse={setResponse}
                    forRoles={forRoles}
                    searchBarPlaceholder={searchBarPlaceholder}
                    pageName={pageName}
                />
            )}
            {enableActiveUserButton && [ROLES.SUPER_ADMIN, ROLES.ADMIN].includes(role) && (
                <span
                    id='action-btn'
                    title={activateUserButtonTitle}
                    aria-label={activateUserButtonTitle}
                    onClick={() => setShowModal(true)}
                    className={`unhide-hc-btn ${response?.data?.length === 0 ? 'no-data' : ''}`}
                >
                    <OptionIcon />
                </span>
            )}
        </>
    );
};

ManagementList.defaultProps = {
    forRoles: [],
    status: true,
    heading: '',
    buttonText: '',
    searchBarPlaceholder: 'Search...',
    columns: [{}, {}],
    stickyColumns: [],
    pageName: '',
    enableActiveUserButton: false,
    activateUserButtonTitle: '',
};

ManagementList.propTypes = {
    forRoles: PropTypes.array.isRequired,
    status: PropTypes.bool,
    heading: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    searchBarPlaceholder: PropTypes.string.isRequired,
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    stickyColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
    pageName: PropTypes.string.isRequired,
    enableActiveUserButton: PropTypes.bool,
    activateUserButtonTitle: PropTypes.string,
};
