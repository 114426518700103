import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { BackArrow } from '../../../assets/svg';
import { AUTH, REGEX } from '../../../constants/strings';
import { HEADERS, MAX_LENGTH, ROUTES, VALIDATION } from '../../../constants';
import { Formik } from 'formik';
import { formValidator, initiateResetPassword, popup } from '../../../utils';
import cloudLogo2 from '../../../assets/img/cloud-logo-2.png';

export function ForgetPassword() {
    const navigation = useNavigate();
    const location = useLocation();
    const isReset = location.state && location.state.isReset;

    const userInputSectionRef = useRef();
    const [isFormFocused, setIsFormFocused] = useState(false);

    const validationSchema = {
        email: {
            regex: REGEX.EMAIL,
            message: VALIDATION.INVALID_EMAIL,
            requiredMessage: VALIDATION.EMAIL_REQUIRED,
        },
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                userInputSectionRef.current &&
                !userInputSectionRef.current.contains(event.target)
            ) {
                setTimeout(() => {
                    setIsFormFocused(false);
                }, 100);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const validateForm = () => setIsFormFocused(true);
    return (
        <Formik
            initialValues={{ email: '' }}
            validate={(values) => {
                const errors = {};
                formValidator({ values, errors, validationSchema });
                return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
                initiateResetPassword({
                    body: { ...values },
                    headers: {
                        [HEADERS.X_FRONTEND_URL]: window.location.origin,
                        [HEADERS.X_RESET_PASSWORD_ROUTE]: ROUTES.CUSTOMER.RESET_PASSWORD,
                    },
                })
                    .then((response) => {
                        setSubmitting(false);
                        navigation(ROUTES.CUSTOMER.LOGIN);
                        popup('success', response.message);
                    })
                    .catch((error) => {
                        setSubmitting(false);
                        popup('error', error.message);
                    });
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                dirty,
                isSubmitting,
                setTouched,
            }) => (
                <form
                    onSubmit={handleSubmit}
                    className='w-100 forget-form d-flex align-items-start justify-content-start flex-column'
                >
                    <div className='extra-spacing-rl w-100'>
                        <div
                            style={{ cursor: 'pointer' }}
                            onClick={() => navigation(ROUTES.CUSTOMER.LOGIN)}
                            className='d-flex justify-content-center'
                        >
                            <img src={cloudLogo2} alt='SSC Cloud' width={260} />
                        </div>
                        <div className='forget-title-bar mt-3'>
                            <h3 className='mb-3'>
                                {isReset ? AUTH.RESET_PASSWORD : AUTH.FORGOT_PASSWORD}
                            </h3>
                            <h5 className='mb-0'>{AUTH.NO_WORRIES}</h5>
                        </div>
                    </div>
                    <div
                        className='user-input-section w-100'
                        ref={userInputSectionRef}
                        onFocus={validateForm}
                    >
                        <div className='input-set w-100'>
                            <label>{AUTH.EMAIL_ADDRESS}</label>
                            <div className='position-relative'>
                                <input
                                    maxLength={MAX_LENGTH.EMAIL}
                                    type='email'
                                    name='email'
                                    onChange={(e) => {
                                        !touched.email && setTouched({ ...touched, email: true });
                                        handleChange(e);
                                    }}
                                    onBlur={handleBlur}
                                    value={values.email}
                                    className={`form-control ${isFormFocused && errors.email && touched.email && errors.email ? 'border border-danger-subtle border-1' : ''}`}
                                    placeholder={AUTH.ENTER_EMAIL_ADDRESS}
                                />
                                {isFormFocused && touched.email && errors.email && (
                                    <p className='error-message'>{errors.email}</p>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='w-100 extra-spacing-rl'>
                        <button
                            type='submit'
                            className='ssc-primary-green-btn'
                            disabled={isSubmitting || !dirty || errors.email}
                        >
                            {AUTH.NEXT}
                        </button>
                    </div>
                    <div className='mt-4 back-btn extra-spacing-rl'>
                        <Link
                            to={ROUTES.CUSTOMER.LOGIN}
                            className='d-flex align-items-center justify-content-center'
                        >
                            <BackArrow />
                            <p>{AUTH.BACK_TO_LOGIN}</p>
                        </Link>
                    </div>
                </form>
            )}
        </Formik>
    );
}
