import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { AdminPageHeader } from '../Common/AdminPagesHeader';
import { CUSTOMER_LIST, CUSTOMER_COLUMNS, ROLES, LOCAL_STORAGE } from '../../../constants';
import { Table, AddNewCustomer } from '../../shared';
import { usePagination, useDebounce } from '../../../hooks';
import { getAllAccounts, popup, getLocalStorageItem, getAllCSM } from '../../../utils';
import { clearCSMCaseload, setCSMCaseload, setLoaderVisibility } from '../../../redux';
import SSCSelect from '../../shared/SSCSelect';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

export const CustomerListing = () => {
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const csmCaseloadData = useSelector((s) => s.csmCaseload?.csmCaseloadData);
    const role = useSelector((s) => s.user.role);
    const userId = getLocalStorageItem(LOCAL_STORAGE.USER_ID);
    const [response, setResponse] = useState({ data: [], count: 0 });
    const [csm, setCsm] = useState(null);
    const selectedCSM = csmCaseloadData
        ? {
              label: csmCaseloadData?.full_name,
              value: csmCaseloadData,
          }
        : null;

    const [csmSearch, setCsmSearch] = useState('');
    const [csmLoading, setCsmLoading] = useState(false);
    const [search, setSearch] = useState(searchParams.get('search') || '');
    const pagination = usePagination(response.data.length, response.count);
    const [showAddModal, setShowAddModal] = useState(false);

    const handleAddClick = () => {
        setShowAddModal(true);
    };

    const debouncedCSMCall = useDebounce(() => {
        setCsmLoading(true);
        getAllCSM({
            offset: 1,
            limit: 25,
            search: csmSearch,
        })
            .then((res) => {
                if (res?.data) {
                    const filteredCsm = res.data.filter(
                        (csm) => csm.role_code === ROLES.CSM && csm.id !== userId,
                    );
                    setCsm(filteredCsm);
                }
            })
            .catch((error) => {
                popup('error', error.message);
            })
            .finally(() => {
                setCsmLoading(false);
            });
    }, 500);

    const debouncedApiCall = useDebounce(() => {
        getAllAccounts({
            offset: pagination.offset,
            limit: pagination.limit,
            search: search,
            id: selectedCSM?.value?.id || '',
            role: selectedCSM?.value?.role_code || '',
        })
            .then((res) => {
                if (res?.data) {
                    setResponse({ data: res.data, count: res.count });
                }
            })
            .catch((error) => {
                popup('error', error.message);
            })
            .finally(() => {
                dispatch(setLoaderVisibility(false));
            });
    }, 500);

    useEffect(() => {
        !search && dispatch(setLoaderVisibility(true));
        debouncedApiCall();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination.limit, pagination.offset, search, selectedCSM?.value?.id]);

    useEffect(() => {
        debouncedCSMCall();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [csmSearch]);

    const onSelectChange = (data) => {
        if (data === null || data === undefined) {
            dispatch(clearCSMCaseload());
        } else {
            dispatch(setCSMCaseload({ csmCaseloadData: data?.value, isCsmCaseload: true }));
        }
    };

    return (
        <>
            <AdminPageHeader
                heading={CUSTOMER_LIST.PAGE_HEADING}
                buttonText={CUSTOMER_LIST.BUTTON_TEXT}
                searchBarPlaceholder={CUSTOMER_LIST.SEARCH_BAR_PLACEHOLDER}
                setSearch={setSearch}
                count={pagination.dataCount}
                showAddButton={[ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.CONTENT_MANAGER].includes(
                    role,
                )}
                handleAddClick={handleAddClick}
                AddModal={
                    showAddModal ? (
                        <AddNewCustomer
                            showAddModal={showAddModal}
                            setShowAddModal={setShowAddModal}
                            debouncedApiCall={debouncedApiCall}
                        />
                    ) : (
                        ''
                    )
                }
                setCurrentPage={pagination.setCurrentPage}
                backbutton
                searchAdjacentChildren={
                    [ROLES.ADMIN, ROLES.CSM].includes(role) ? (
                        <div className='col-12 col-md-5 col-xxl-4'>
                            <SSCSelect
                                className={'w-100 w-md-50'}
                                options={csm?.map((csm) => ({ label: csm.full_name, value: csm }))}
                                placeholder={'Apply CSM caseload...'}
                                onChange={onSelectChange}
                                onInputChange={(v) => {
                                    setCsmSearch(v);
                                }}
                                isLoading={csmLoading}
                                isClearable={true}
                                value={selectedCSM}
                            />
                        </div>
                    ) : null
                }
            />

            <Table
                columns={
                    [ROLES.ADMIN, ROLES.SUPER_ADMIN].includes(role)
                        ? CUSTOMER_COLUMNS
                        : CUSTOMER_COLUMNS.filter((columns) => columns.name !== 'Created By')
                }
                data={response.data}
                pagination={pagination}
                pageName={CUSTOMER_LIST.PAGE_NAME}
                search={search}
                debouncedApiCall={debouncedApiCall}
                stickyColumns={['Client Name']}
            />
        </>
    );
};
