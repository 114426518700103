import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import {
    ROUTES,
    SCORECARD_REPORT,
    ERROR,
    ADMIN_SCORE_CARD_LISTING_COLUMNS,
} from '../../../constants';
import { usePagination, useDebounce } from '../../../hooks';
import { setLoaderVisibility } from '../../../redux/slices/loaderSlice';
import { EmptyImg } from '../../../assets/svg';
import { AdminScoreCardHeader } from './AdminScoreCardHeader';
import { getAllPlatforms, popup, getAllScoreCardDetails } from '../../../utils';
import { Table } from '../../shared';
import { AdminScoreCardCreateModal } from './AdminScoreCardCreateModal';
import { useSelector } from 'react-redux';

export const AdminScoreCardListing = () => {
    // declaration

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const searchParam = useSelector((s) => s.search.query);
    const [platforms, setPlatforms] = useState([]);
    const [grade, setGrade] = useState('All grades');
    const [search, setSearch] = useState(searchParam);
    const [selectedPlatform, setSelectedPlatform] = useState([]);
    const [scorecards, setScorecards] = useState({ data: [], count: 0 });
    const pagination = usePagination(scorecards.data.length, scorecards.count);
    const [openCreateModal, setOpenCreateModal] = useState(false);
    const availableGradeOptions = ['All grades', 'red', 'yellow', 'green'];
    const csmCaseLoad = useSelector((s) => s.csmCaseload?.csmCaseloadData);

    // functions

    const getAllPlatformsData = async () => {
        const response = await getAllPlatforms();
        setPlatforms(response.data);
    };

    const handleAddClick = () => {
        setOpenCreateModal(true);
    };

    const debouncedApiCall = useDebounce(() => {
        getAllScoreCardDetails({
            offset: pagination.offset,
            limit: pagination.limit,
            search: search ? search : '',
            selectedPlatform: selectedPlatform ? selectedPlatform : {},
            list: true,
            grade: grade && grade !== 'All grades' ? grade : '',
            id: csmCaseLoad?.id || '',
            role: csmCaseLoad?.role_code || '',
        })
            .then((res) => {
                dispatch(setLoaderVisibility(false));
                if (res.status === 'error') {
                    if (res.statusCode === ERROR.INTERNAL_SERVER_ERROR) {
                        navigate(ROUTES.SERVER_ERROR);
                    }
                    popup('error', res.message);
                } else if (res?.data) setScorecards({ data: res.data, count: res.count });
            })
            .catch((error) => {
                if (error.status === 'error' && error.statusCode === ERROR.INTERNAL_SERVER_ERROR) {
                    navigate(ROUTES.SERVER_ERROR);
                }
                dispatch(setLoaderVisibility(false));
            });
    }, 1000);

    //initial state

    useEffect(() => {
        getAllPlatformsData();
        return () => {
            setPlatforms([]);
        };
    }, []);

    useEffect(() => {
        !search && dispatch(setLoaderVisibility(true));
        debouncedApiCall();
        return () => {
            setScorecards({ data: [], count: 0 });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination.limit, pagination.offset, search, grade, csmCaseLoad?.id]);

    return (
        <>
            <AdminScoreCardHeader
                platforms={platforms}
                availableGrades={availableGradeOptions}
                setSelectedPlatform={setSelectedPlatform}
                selectedPlatform={selectedPlatform}
                setSearch={setSearch}
                debouncedApiCall={debouncedApiCall}
                grade={grade}
                setGrade={setGrade}
                handleAddClick={handleAddClick}
                heading={SCORECARD_REPORT.SCORE_CARD_LISTING_PAGE.HEADER_HEADING}
                buttonText={SCORECARD_REPORT.SCORE_CARD_LISTING_PAGE.HEADER_BUTTON}
                searchBarPlaceholder={
                    SCORECARD_REPORT.SCORE_CARD_LISTING_PAGE.SEARCH_BAR_PLACEHOLDER
                }
                search={search}
            />

            {openCreateModal ? (
                <AdminScoreCardCreateModal
                    openCreateModal={openCreateModal}
                    setOpenCreateModal={setOpenCreateModal}
                />
            ) : (
                ''
            )}

            {scorecards.data.length ? (
                <Table
                    columns={ADMIN_SCORE_CARD_LISTING_COLUMNS}
                    data={scorecards.data}
                    pagination={pagination}
                    pageName={SCORECARD_REPORT.SCORE_CARD_LISTING_PAGE.HEADER_HEADING}
                    debouncedApiCall={debouncedApiCall}
                    stickyColumns={['Client']}
                />
            ) : (
                <div className='pt-5 pt-3 empty-set d-flex align-items-center justify-content-center flex-column'>
                    <EmptyImg />
                    <p>{SCORECARD_REPORT.SCORE_CARD_LISTING_PAGE.NO_SCORECARDS}</p>
                    <button
                        className='w-auto ssc-primary-green-btn'
                        type='button'
                        data-bs-toggle='modal'
                        data-bs-target='#exampleModal-add'
                        onClick={handleAddClick}
                    >
                        {SCORECARD_REPORT.SCORE_CARD_LISTING_PAGE.HEADER_BUTTON}
                    </button>
                </div>
            )}
        </>
    );
};
