import { ROLES, ROUTES } from '../../../constants';
import {
    Admin,
    Csm,
    Customer,
    DocumentRequest,
    DashboardIcon,
    Documents,
    DocumentCategory,
    ScoreCard,
    HiringClients,
    Broadcast,
    SafetyMeetingIcon,
    User,
} from '../../../assets/svg';

export const sidebarOptions = (sidebarFor) => {
    return sidebarFor === 'admin'
        ? [
              {
                  name: 'Dashboard',
                  icon: DashboardIcon,
                  route: [ROUTES.ADMIN.DASHBOARD],
                  allowedRoles: [
                      ROLES.SUPER_ADMIN,
                      ROLES.ADMIN,
                      ROLES.CSM,
                      ROLES.CONTENT_MANAGER,
                      ROLES.SALES_AGENT,
                  ],
              },
              {
                  name: 'Document Categories',
                  icon: DocumentCategory,
                  route: [ROUTES.ADMIN.DOCUMENT_CATEGORY],
                  allowedRoles: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.CSM],
              },
              {
                  name: 'Documents',
                  icon: Documents,
                  route: [ROUTES.ADMIN.DOCUMENTS],
                  allowedRoles: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.CSM],
              },
              {
                  name: 'Management',
                  icon: User,
                  route: [],
                  allowedRoles: [
                      ROLES.SUPER_ADMIN,
                      ROLES.ADMIN,
                      ROLES.CSM,
                      ROLES.SALES_AGENT,
                      ROLES.CONTENT_MANAGER,
                  ],
                  subOptions: [
                      {
                          name: 'Admins',
                          icon: Admin,
                          route: [ROUTES.ADMIN.ADMIN],
                          allowedRoles: [
                              ROLES.SUPER_ADMIN,
                              ROLES.ADMIN,
                              ROLES.CSM,
                              ROLES.SALES_AGENT,
                          ],
                      },
                      {
                          name: 'CSMs',
                          icon: Csm,
                          route: [ROUTES.ADMIN.CSM],
                          allowedRoles: [
                              ROLES.SUPER_ADMIN,
                              ROLES.ADMIN,
                              ROLES.CSM,
                              ROLES.CONTENT_MANAGER,
                              ROLES.SALES_AGENT,
                          ],
                      },
                      {
                          name: 'Content Manager',
                          icon: Csm,
                          route: [ROUTES.ADMIN.CONTENT_MANAGER],
                          allowedRoles: [
                              ROLES.SUPER_ADMIN,
                              ROLES.ADMIN,
                              ROLES.CSM,
                              ROLES.CONTENT_MANAGER,
                              ROLES.SALES_AGENT,
                          ],
                      },
                      {
                          name: 'Sales Agents',
                          icon: Csm,
                          route: [ROUTES.ADMIN.SALES_AGENT],
                          allowedRoles: [
                              ROLES.SUPER_ADMIN,
                              ROLES.ADMIN,
                              ROLES.CSM,
                              ROLES.CONTENT_MANAGER,
                              ROLES.SALES_AGENT,
                          ],
                      },
                  ],
              },
              {
                  name: 'Clients',
                  icon: Customer,
                  route: [
                      ROUTES.ADMIN.CUSTOMER,
                      ROUTES.ADMIN.NEWS_FEED_ADMIN,
                      ROUTES.ADMIN.CUSTOMER_EDIT,
                      ROUTES.ADMIN.CUSTOMER_HIRING_CLIENT_ORDER,
                  ],
                  allowedRoles: [
                      ROLES.SUPER_ADMIN,
                      ROLES.ADMIN,
                      ROLES.CSM,
                      ROLES.CONTENT_MANAGER,
                      ROLES.SALES_AGENT,
                  ],
              },
              {
                  name: 'Request Document',
                  icon: DocumentRequest,
                  route: [ROUTES.ADMIN.DOCUMENT_REQUEST],
                  allowedRoles: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.CSM],
              },
              {
                  name: 'Scorecards',
                  icon: ScoreCard,
                  route: [
                      ROUTES.ADMIN.ADMIN_SCORE_CARD_LISTING,
                      ROUTES.ADMIN.SCORE_CARD_REPORT_ADMIN,
                  ],
                  allowedRoles: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.CSM],
              },
              {
                  name: 'Regulatory Issue',
                  icon: Broadcast,
                  route: [ROUTES.ADMIN.ADMIN_REGULATORY_ISSUE],
                  allowedRoles: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.CSM, ROLES.SALES_AGENT],
              },
              // Digital Document Library
              {
                  name: 'Digital Document Library',
                  icon: Documents,
                  route: [],
                  allowedRoles: [
                      ROLES.SUPER_ADMIN,
                      ROLES.ADMIN,
                      ROLES.CSM,
                      ROLES.CONTENT_MANAGER,
                      ROLES.SALES_AGENT,
                  ],
                  subOptions: [
                      {
                          name: 'Digital Safety Manuals',
                          icon: DocumentCategory,
                          route: [ROUTES.ADMIN.DIGITAL_SAFETY_LIBRARY],
                          allowedRoles: [
                              ROLES.SUPER_ADMIN,
                              ROLES.ADMIN,
                              ROLES.CSM,
                              ROLES.CONTENT_MANAGER,
                              ROLES.SALES_AGENT,
                          ],
                      },
                      {
                          name: 'Safety Meetings',
                          icon: SafetyMeetingIcon,
                          route: [ROUTES.ADMIN.SAFETY_MEETINGS],
                          allowedRoles: [
                              ROLES.SUPER_ADMIN,
                              ROLES.ADMIN,
                              ROLES.CSM,
                              ROLES.CONTENT_MANAGER,
                              ROLES.SALES_AGENT,
                          ],
                      },
                  ],
              },
          ]
        : [
              {
                  name: 'Dashboard',
                  icon: DashboardIcon,
                  route: [ROUTES.CUSTOMER.DASHBOARD],
              },
              {
                  name: 'Documents',
                  icon: Documents,
                  route: [ROUTES.CUSTOMER.DOCUMENTS],
              },
              {
                  name: 'Scorecard',
                  icon: ScoreCard,
                  route: [ROUTES.CUSTOMER.SCORE_CARD_REPORT],
              },
              {
                  name: 'Hiring Clients',
                  icon: HiringClients,
                  route: [ROUTES.CUSTOMER.HIRING_CLIENTS],
              },
              {
                  name: 'Digital Document Library',
                  icon: Documents,
                  route: [],
                  subOptions: [
                      {
                          name: 'Digital Safety Manuals',
                          icon: DocumentCategory,
                          route: [ROUTES.CUSTOMER.DIGITAL_SAFETY_LIBRARY],
                      },
                      {
                          name: 'Safety Meetings',
                          icon: SafetyMeetingIcon,
                          route: [ROUTES.CUSTOMER.SAFETY_MEETINGS],
                      },
                  ],
              },
          ];
};
