import React from 'react';
import { ADMIN_COLUMNS, ADMIN, ROLES } from '../../../constants';
import { useSelector } from 'react-redux';
import { ManagementList } from '../ManagementList/ManagementList';

export const AdminListing = () => {
    const isSuperAdmin = useSelector((s) => s.user.role) === ROLES.SUPER_ADMIN;
    return (
        <ManagementList
            forRoles={[ROLES.ADMIN, ROLES.SUPER_ADMIN]}
            buttonText={ADMIN.BUTTON_TEXT}
            stickyColumns={['Admin Name']}
            pageName={ADMIN.PAGE_NAME}
            searchBarPlaceholder={ADMIN.SEARCH_BAR_PLACEHOLDER}
            heading={ADMIN.PAGE_HEADING}
            columns={
                isSuperAdmin ? ADMIN_COLUMNS : ADMIN_COLUMNS.filter((col) => col.name !== 'Action')
            }
        />
    );
};
