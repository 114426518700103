import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { debounce, isEmpty } from 'lodash';
import { setLoaderVisibility } from '../../../redux';
import { ConfirmationBox } from '../../shared';
import {
    addNewAccountHcPlatform,
    deleteAccountHcPlatform,
    getAllHiringClient,
    getLocalStorageItem,
    popup,
} from '../../../utils';
import { EDIT_CUSTOMER as EDIT, HIRING_CLIENT, LOCAL_STORAGE, SOURCE } from '../../../constants';
import { AddHiringClient } from './AddHiringClient';
import useWindowSize from '../../../hooks/useWindowSize';
import { Delete, IconPlus } from '../../../assets/svg';
import SSCSelect from '../../shared/SSCSelect';

export const HiringClientSection = ({
    handleClose,
    id,
    tpa,
    realData,
    hiringClientData,
    setHiringClientData,
    debouncedApiCall,
}) => {
    const dispatch = useDispatch();
    const [addedHC, setAddedHC] = useState('');
    const [newHC, setNewHC] = useState('');
    const [searchHC, setSearchHC] = useState('');
    const [dropdownData, setDropDownData] = useState([]);
    const [hcToDelete, setHcToDelete] = useState([]);
    const [openConfirmationBox, setOpenConfirmationBox] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const { width } = useWindowSize();
    const userId = getLocalStorageItem(LOCAL_STORAGE.USER_ID);

    const updateGradeInHiringClientData = (e, selectedHiringClient, setHiringClientData) => {
        const { value } = e.target;
        const indexInHCArray = hiringClientData.findIndex((pf) => pf.id === tpa.id);
        if (indexInHCArray !== -1) {
            const indexInHC = hiringClientData[indexInHCArray].hiringClients.findIndex(
                (oldHC) => oldHC.id === selectedHiringClient.id,
            );
            if (indexInHC !== -1) {
                setHiringClientData((pre) => {
                    const oldData = [...pre];
                    oldData[indexInHCArray].hiringClients[indexInHC].hiring_client_grade = value;
                    return [...oldData];
                });
            }
        }
    };

    // if new HC Added
    useEffect(() => {
        if (addedHC) {
            setSearchHC('');
            if (
                tpa?.hiringClients ||
                hiringClientData.filter((pf) => pf.id === tpa.id)[0]?.hiringClients
            ) {
                if (
                    hiringClientData
                        .filter((pf) => pf.id === tpa.id)[0]
                        ?.hiringClients?.some((hc) => hc.id === addedHC.id)
                ) {
                    popup('error', EDIT.POPUP_MSG.HIRING_CLIENT_EXIST);
                } else {
                    setHiringClientData((pre) =>
                        pre.map((pf) =>
                            pf.id === tpa.id
                                ? { ...pf, hiringClients: [...pf.hiringClients, addedHC] }
                                : pf,
                        ),
                    );
                }
            } else {
                setHiringClientData((pre) =>
                    pre.map((pf) => (pf.id === tpa.id ? { ...pf, hiringClients: [addedHC] } : pf)),
                );
            }
        }
        setAddedHC('');
    }, [addedHC]);

    // To populate data in Dropdown if searched
    const getAllHiringClientDebounced = useCallback(
        debounce(async (searchHC) => {
            const res = await getAllHiringClient({ offset: 1, limit: 25, search: searchHC });
            const ids = new Set(tpa?.hiringClients?.map((e) => e.id));
            setDropDownData(res.data.filter((hc) => !ids.has(hc.id)));
        }, 400),
        [],
    );
    useEffect(() => {
        getAllHiringClientDebounced(searchHC);
    }, [searchHC, getAllHiringClientDebounced]);

    // after Confirmation from checkbox
    useEffect(() => {
        if (confirm) {
            saveData();
        }
    }, [confirm]);

    // handle delete click
    const handleDeleteHC = (ids) => {
        const idsToDelete = Array.isArray(ids) ? ids : [ids];
        const hcAfterDelete =
            hiringClientData
                ?.find((pf) => pf.id === tpa.id)
                ?.hiringClients.filter((hc) => !idsToDelete.includes(hc.id)) || [];

        setHiringClientData((prev) =>
            prev.map((pf) => (pf.id === tpa.id ? { ...pf, hiringClients: hcAfterDelete } : pf)),
        );
        setHcToDelete([]); // Reset selection if doing bulk delete
    };

    // handle remove click
    const removeItem = (hc) => {
        const hcAfterRemove = hiringClientData
            .filter((pf) => pf.id === tpa.id)[0]
            .hiringClients.filter((HC) => HC.id !== hc.id);
        setHiringClientData((pre) =>
            pre.map((pf) => (pf.id === tpa.id ? { ...pf, hiringClients: hcAfterRemove } : pf)),
        );
    };

    // Select Hiring CLients
    const handleSelect = (id) => {
        if (!hcToDelete.includes(id)) {
            setHcToDelete((pre) => [...pre, id]);
        } else {
            setHcToDelete((pre) => pre.filter((hc) => hc !== id));
        }
    };

    // Handle save button to open confirmation box
    const handleSaveHC = () => {
        if (realData !== hiringClientData) {
            setOpenConfirmationBox(true);
        } else {
            popup('error', EDIT.POPUP_MSG.NOTHING_TO_SAVE);
        }
    };

    // API call to update Data
    const saveData = () => {
        setConfirm(false);
        setHcToDelete([]);
        dispatch(setLoaderVisibility(true));
        let finalData = [];
        if (hiringClientData.length) {
            hiringClientData
                .map((pf) =>
                    pf?.hiringClients?.length
                        ? pf.hiringClients.map((hc, index) => {
                              finalData.push({
                                  account_id: id,
                                  hiring_client_id: hc.id,
                                  platform_id: pf.id,
                                  hc_order: index + 1,
                                  hiring_client_grade: hc.hiring_client_grade || '',
                                  platform_grade: pf.grade || null,
                                  updated_by: userId,
                              });
                          })
                        : finalData.push({
                              account_id: id,
                              hiring_client_id: null,
                              platform_id: pf.id,
                              hc_order: 0,
                              hiring_client_grade: '',
                              platform_grade: null,
                              updated_by: userId,
                          }),
                )
                .flat();
        } else if (!hiringClientData.length) {
            deleteAccountHcPlatform({ body: { account_id: id } })
                .then(() => {
                    debouncedApiCall();
                    popup('success', EDIT.POPUP_MSG.PLATFORM_UPDATED);
                    handleClose();
                })
                .catch((err) => {
                    dispatch(setLoaderVisibility(false));
                    popup('error', err.message);
                });
        }
        if (finalData?.length) {
            addNewAccountHcPlatform({ body: finalData })
                .then(() => {
                    debouncedApiCall();
                    popup('success', EDIT.POPUP_MSG.PLATFORM_UPDATED);
                    handleClose();
                })
                .catch((err) => {
                    dispatch(setLoaderVisibility(false));
                    popup('error', err.message);
                });
        }
    };

    return (
        <div
            className='border user-create-form mt-2 px-2 mb-5'
            style={{ marginInline: 0 }}
            key={tpa?.id}
        >
            <div
                className={`wrapper position-relative d-flex align-items-center gap-3 justify-content-between`}
                id='wrapper'
            >
                <div
                    className='d-flex flex-wrap align-items-center w-100 gap-3'
                    style={{ zIndex: 109 }}
                >
                    <div className=' d-flex justify-content-start align-items-center gap-3 w-100'>
                        <div className='d-flex justify-content-start align-items-center gap-4 w-100'>
                            <SSCSelect
                                placeholder={HIRING_CLIENT.SEARCH_BAR_PLACEHOLDER}
                                options={dropdownData.map((data) => ({
                                    label: data.name,
                                    value: data,
                                }))}
                                value={addedHC}
                                onChange={(v) => setAddedHC(v.value)}
                                onInputChange={setSearchHC}
                                className={width > 720 ? 'w-50' : 'w-75'}
                            />
                            {width > 720 ? (
                                <button
                                    onClick={() => setNewHC((pre) => !pre)}
                                    className='ssc-primary-green-btn button-green-filled'
                                    style={{ minWidth: 'max-content' }}
                                >
                                    {EDIT.BUTTON_TEXT.ADD_NEW_HC}
                                </button>
                            ) : (
                                <button
                                    id='action-btn-green'
                                    title={EDIT.BUTTON_TEXT.ADD_NEW_HC}
                                    onClick={() => setNewHC((pre) => !pre)}
                                >
                                    <IconPlus />
                                </button>
                            )}
                            {hcToDelete.length > 1 && (
                                <button
                                    title='Delete Selected Hiring Clients'
                                    onClick={() => handleDeleteHC(hcToDelete)}
                                    className='border-0 bg-white'
                                >
                                    <Delete width={22} height={22} />
                                </button>
                            )}
                        </div>
                    </div>
                </div>
                {/* CLOSE BUTTON */}
                <div className='d-flex justify-content-end align-items-start'>
                    <button
                        type='button'
                        className='btn-close mt-0'
                        data-bs-dismiss='modal'
                        aria-label='Close'
                        onClick={handleClose}
                    />
                </div>
            </div>

            <div className='scroll-manage-platform mt-2 flex-grow-1 flex-fill draggable'>
                {newHC && <AddHiringClient setNewHC={setNewHC} setAddedHC={setAddedHC} />}
                {!isEmpty(hiringClientData.filter((pf) => pf?.id === tpa?.id)[0]?.hiringClients) ? (
                    <table className='table mt-2'>
                        <thead style={{ top: 0, zIndex: 99 }}>
                            <tr>
                                <th className='edit-box-row'>Select</th>
                                <th className='text-nowrap sticky-th-left'>Hiring Client Name</th>
                                <th className='text-nowrap'>Created By</th>
                                <th>Grade</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {hiringClientData
                                .filter((pf) => pf.id === tpa.id)[0]
                                .hiringClients.map((hc) => (
                                    <tr key={hc.id}>
                                        <td className='edit-box-row'>
                                            {tpa?.hiringClients?.some((HC) => HC.id === hc.id) ? (
                                                <input
                                                    onChange={() => handleSelect(hc.id)}
                                                    type='checkbox'
                                                    className='cursor-pointer'
                                                    checked={
                                                        hcToDelete.includes(hc.id) ? true : false
                                                    }
                                                />
                                            ) : (
                                                <button
                                                    onClick={() => removeItem(hc)}
                                                    className='btn-close'
                                                ></button>
                                            )}
                                        </td>
                                        <td className='sticky-td-left'>{hc.name}</td>
                                        <td>
                                            {hc.is_from_salesforce ? (
                                                <span className='text-danger'>
                                                    {SOURCE.SCHEDULAR}
                                                </span>
                                            ) : (
                                                SOURCE.PORTAL
                                            )}
                                        </td>
                                        <td>
                                            <div className='input-set w-100 input-set-gap'>
                                                <input
                                                    type='text'
                                                    name='grade-text'
                                                    onChange={(e) =>
                                                        updateGradeInHiringClientData(
                                                            e,
                                                            hc,
                                                            setHiringClientData,
                                                        )
                                                    }
                                                    placeholder={'Enter Grade'}
                                                    value={hc.hiring_client_grade || ''}
                                                    className={`form-control max-width-40-percent`}
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <button
                                                id='action-btn'
                                                title='Delete'
                                                onClick={() => handleDeleteHC(hc.id)}
                                                className='bg-white'
                                            >
                                                <Delete width={16} height={16} />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                ) : (
                    <div className='text-special text-center ms-5 mt-3'>
                        {EDIT.BUTTON_TEXT.NO_HC}
                    </div>
                )}
            </div>
            <div className='d-flex align-items-center justify-content-end pt-lg-2 border-top'>
                <button
                    onClick={handleSaveHC}
                    className='ssc-primary-green-btn button-green-filled form-button d-flex align-items-center justify-content-center border-0'
                >
                    {EDIT.BUTTON_TEXT.SAVE}
                </button>
            </div>
            <ConfirmationBox
                setConfirm={setConfirm}
                openConfirmationBox={openConfirmationBox}
                setOpenConfirmationBox={setOpenConfirmationBox}
                customMessage={EDIT.POPUP_MSG.ARE_YOU_SURE}
            />
        </div>
    );
};

HiringClientSection.propTypes = {
    id: PropTypes.string,
    handleClose: PropTypes.func,
    realData: PropTypes.array,
    tpa: PropTypes.object,
    hiringClientData: PropTypes.array,
    setHiringClientData: PropTypes.func,
    debouncedApiCall: PropTypes.func,
};
