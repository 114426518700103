/*
Contains constants related to application routes, 
such as route paths, route names, or route parameters.
*/
export const ROUTES = {
    HOME: '/',
    EMPTY: '',
    SERVER_ERROR: '/server_error',
    CUSTOMER: {
        DASHBOARD: '/customer/dashboard',
        DEFAULT: '/customer',
        DIGITAL_SAFETY_LIBRARY: '/customer/digital_safety_library',
        DOCUMENTS: '/customer/documents',
        EDIT_PROFILE: '/customer/edit_profile',
        FORGOT_PASSWORD: '/forgot_password',
        HIRING_CLIENTS: '/customer/hiring_clients',
        LEGEND: '/customer/legend',
        LOGIN: '/portal/login',
        NEWS_FEEDS: '/customer/news_feeds',
        NOTIFICATION: '/customer/notification',
        RESET_PASSWORD: '/reset_password',
        SAFETY_MEETINGS: '/customer/safety_meetings',
        SCORE_CARD_REPORT: '/customer/score_card_report',
        SIGNUP: '/portal/signup',
        UPLOAD: '/upload',
    },
    ADMIN: {
        ADMIN: '/admin/admin_list',
        ADMIN_REGULATORY_ISSUE: '/admin/regulatory_issue',
        ADMIN_SCORE_CARD_LISTING: '/admin/score_card_listing',
        CONTENT_MANAGER: '/admin/content_manager_list',
        CSM: '/admin/csm_list',
        CUSTOMER: '/admin/customer',
        CUSTOMER_EDIT: '/admin/customer/edit',
        CUSTOMER_HIRING_CLIENT_ORDER: '/admin/hiring_client_order',
        DASHBOARD: '/admin/dashboard',
        DEFAULT: '/admin',
        DIGITAL_SAFETY_LIBRARY: '/admin/digital_safety_library',
        DOCUMENT_CATEGORY: '/admin/document_category',
        DOCUMENT_REQUEST: '/admin/document_request',
        DOCUMENTS: '/admin/documents',
        EDIT_PROFILE: '/admin/edit_profile',
        LEGEND: '/admin/legend',
        LOGIN: '/login',
        NEWS_FEED_ADMIN: '/admin/news_feed',
        NOTIFICATION: '/admin/notification',
        SAFETY_MEETINGS: '/admin/safety_meetings',
        SALES_AGENT: '/admin/sales_agent_list',
        SCORE_CARD_REPORT_ADMIN: '/admin/score_card_report',
        USER_VIEW: '/admin/user/view',
    },
};
