import React from 'react';
import { CardRightArrow } from '../../../assets/svg';
import { Link } from 'react-router-dom';
import SidebarItem from './SidebarItem';

const SidebarSubOptionItems = ({
    index,
    option,
    setAccordionOpen,
    accordionOpen,
    setCurrentDashboardRoute,
    currentDashboardRoute,
    sidebarFor,
    isUserPrimary,
    role,
    width,
    show_sidebar,
}) => {
    return width <= 991 && !show_sidebar ? (
        <SidebarItem
            key={index}
            index={index}
            option={option}
            sidebarFor={sidebarFor}
            isUserPrimary={isUserPrimary}
            currentDashboardRoute={currentDashboardRoute}
            setCurrentDashboardRoute={setCurrentDashboardRoute}
            role={role}
        />
    ) : (
        <li className={`links-set`} key={index} style={{ userSelect: 'none' }}>
            <div
                className='d-flex align-items-center justify-content-start link-line'
                onClick={() => {
                    if (accordionOpen.includes(index)) {
                        setAccordionOpen((prev) => prev.filter((item) => item !== index));
                    } else {
                        setAccordionOpen((prev) => [...prev, index]);
                    }
                }}
                style={{ cursor: 'pointer' }}
                title={option.name}
            >
                <option.icon color='#738DA6' height={16} width={16} />
                <div style={{ color: 'var(--col-sidebar-text)' }} className='sidebar-ellipses'>
                    {option.name}
                </div>
                <i className={accordionOpen.includes(index) ? 'rotate-svg-90' : ''}>
                    <CardRightArrow />
                </i>
            </div>
            {accordionOpen.includes(index) && (
                <ul
                    className='links-set ps-4'
                    style={{
                        listStyle: 'none',
                    }}
                >
                    {option.subOptions.map((subOption, subIndex) => {
                        if (
                            sidebarFor !== 'admin' &&
                            subOption.name === 'Safety Meetings' &&
                            !isUserPrimary
                        ) {
                            return null;
                        }

                        if (sidebarFor === 'admin' && !subOption?.allowedRoles.includes(role)) {
                            return null;
                        }

                        return (
                            <li key={subIndex} className='links-set' title={subOption.name}>
                                <Link
                                    onClick={() => {
                                        setCurrentDashboardRoute(subOption.route[0]);
                                    }}
                                    to={subOption.route[0]}
                                    className={`d-flex align-items-center justify-content-start link-line ${
                                        subOption.route.includes(currentDashboardRoute) && 'active'
                                    }`}
                                >
                                    <subOption.icon color='#738DA6' height={16} width={16} />
                                    <span className='sidebar-ellipses'>{subOption.name}</span>
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            )}
        </li>
    );
};

export default SidebarSubOptionItems;
