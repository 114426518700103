/* eslint-disable react/jsx-no-comment-textnodes */
import React from 'react';
import PropTypes from 'prop-types';
import { CUSTOMER_LIST, DOCUMENTS } from '../../../constants';
import { Draggable } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import { setSidePanel } from '../../../redux';

export const TableBody = ({
    data,
    columns,
    setTabData,
    setTabOpen,
    pageName,
    debouncedApiCall,
    setDocumentTabOpen,
    selectedRowIndex = null,
    setSelectedRowIndex,
    stickyColumns,
    isScrollableWidth,
    isDraggable,
}) => {
    const dispatch = useDispatch();
    const showPointer = pageName === CUSTOMER_LIST.PAGE_NAME || pageName === DOCUMENTS.PAGE_NAME;
    const handleTabOpen = (data, rowIndex) => {
        if (pageName === CUSTOMER_LIST.PAGE_NAME) {
            setTabData(data.id);
            setTabOpen(true);
            dispatch(setSidePanel({ sidePanel: true, id: data.id }));
            setSelectedRowIndex(rowIndex);
        } else if (pageName === DOCUMENTS.PAGE_NAME) {
            setTabData(data);
            setDocumentTabOpen(true);
            setSelectedRowIndex(rowIndex);
        }
    };

    return (
        <>
            <tbody>
                {data.map((item, index) => {
                    const rowIndex = index + 1;
                    return (
                        <Draggable
                            key={`${item?.id}-${rowIndex}` || rowIndex}
                            draggableId={rowIndex.toString()}
                            index={rowIndex}
                            isDragDisabled={!isDraggable}
                        >
                            {(provided, snapshot) => (
                                <tr
                                    className={`${showPointer ? ' mouse-pointer ' : ''} ${selectedRowIndex === rowIndex ? ' selected-table-row ' : ''} ${snapshot.isDragging ? 'draggable-row' : ''}`}
                                    key={item?.id || rowIndex} // Use a stable key based on the item's ID or index
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                >
                                    {columns.map((column, colIndex) => {
                                        if (!column?.hideColumn)
                                            return (
                                                <td
                                                    key={colIndex}
                                                    onClick={() =>
                                                        column?.name !== 'Action'
                                                            ? handleTabOpen(item, rowIndex)
                                                            : {}
                                                    }
                                                    className={`${column.classNameTD} ${isScrollableWidth && (stickyColumns.includes(column.name) || stickyColumns.includes(colIndex)) ? ' sticky-th-left ' : ''} ${snapshot.isDragging ? 'draggable-row-data' : ''}`}
                                                >
                                                    {column?.customFormatter
                                                        ? column.customFormatter(
                                                              column.dataField.reduce(
                                                                  (acc, field) => {
                                                                      acc[field] = item[field];
                                                                      return acc;
                                                                  },
                                                                  {},
                                                              ),
                                                              debouncedApiCall,
                                                          )
                                                        : (column.dataField[0] === 'email'
                                                              ? item[
                                                                    column.dataField[0]
                                                                ]?.toLowerCase()
                                                              : item[column.dataField[0]]) ?? '-'}
                                                </td>
                                            );
                                    })}
                                </tr>
                            )}
                        </Draggable>
                    );
                })}
            </tbody>
        </>
    );
};

TableBody.propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    debouncedApiCall: PropTypes.func,
    setTabData: PropTypes.func,
    setTabOpen: PropTypes.func,
    pageName: PropTypes.string,
    selectedRowIndex: PropTypes.number,
    setSelectedRowIndex: PropTypes.func,
    isDraggable: PropTypes.bool,
};
