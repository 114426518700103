import React from 'react';

import { ManagementList } from '../ManagementList/ManagementList';
import { CONTENT_MANAGER, CSM_COLUMNS, ROLE_NAME, ROLES } from '../../../constants';

export const ContentManagerList = () => {
    return (
        <ManagementList
            enableActiveUserButton
            forRoles={[ROLES.CONTENT_MANAGER]}
            activateUserButtonTitle={`Activate ${ROLE_NAME.CONTENT_MANAGER}s`}
            buttonText={CONTENT_MANAGER.BUTTON_TEXT}
            stickyColumns={['Name']}
            pageName={CONTENT_MANAGER.PAGE_HEADING}
            searchBarPlaceholder={CONTENT_MANAGER.SEARCH_BAR_PLACEHOLDER}
            heading={CONTENT_MANAGER.PAGE_HEADING}
            columns={CSM_COLUMNS}
        />
    );
};
