import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    query: '',
    sidePanel: false,
    selectedRowId: '',
};

const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        setSearchParams: (state, action) => {
            state.query = action.payload.query;
        },
        setSidePanel: (state, action) => {
            state.sidePanel = action.payload.sidePanel;
            state.selectedRowId = action.payload.id;
        },
        clearSearchParams: (state) => {
            state.query = '';
        },
    },
});

export const { setSearchParams, clearSearchParams, setSidePanel } = searchSlice.actions;
export default searchSlice.reducer;
